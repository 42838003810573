import OktaService from '@/services/okta.service'

export default {
  computed: {
    state() {
      return _.get(this.$route, 'query.state', '/')
    }
  },
  methods: {
    login() {
      OktaService.login(this.state)
    }
  }
}
